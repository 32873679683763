import React, { useCallback, useEffect, useState } from "react"
import CartContainer from "../../components/CartContainer"
import OrderSummary from "../../components/OrderSummary"
import { MainContentContainer, SideContainer } from "./styles"
import SecureCheckout from "../../components/SecureCheckout"
import PaymentInfo from "../../components/PaymentInfo"
import { getCookie, setCookie, removeCookie } from "../../utils/cookies"
import { useAppDispatch } from "../../redux/configureStore"
import { Subscription, getActiveSubscriptions } from "../../redux/subscription"
import { getPaymentMethods } from "../../redux/creditcard"
import { useSelector } from "react-redux"
import { AppState } from "../../redux/configureStore"
import { getProration } from "../../redux/network/prorate"
import { Product, getProducts } from "../../redux/product"
import { useParams } from "react-router-dom"
import ErrorModal from "../../components/ErrorModal"
import { useAuth } from "../../providers/AuthProvider"
import {validateOrgDiscount} from "../../redux/guest/validateOrgDiscount";
import { useOrgDiscount } from "../../hooks/useOrgDiscount"
import ValidateNomaEmailDialog from "../../components/ValidateNomaEmailDialog";

type Props = {
  page: number
  setPage: (num: number) => void
}

type Address = {
  address1: string
  address2: string
  city: string
  state: string
  postal_code: string
  country: string
}

const View = ({ page, setPage }: Props) => {
  const auth = useAuth()
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const { orgId, isOrgCheckout } = useOrgDiscount();
  const [isNomaDialogOpen, setIsNomaDialogOpen] = useState(isOrgCheckout());
  const [isPageLoaded, setIsPageLoaded] = useState(false)
  const [open, setOpen] = useState(false)
  const [pageLoadingComplete, setPageLoadingComplete] = useState(false)
  const [couponCode, setCouponCode] = useState(
    process.env.REACT_APP_COUPON_CODE ?? ""
  )

  const products = useSelector(
    (state: AppState) => state.product.cartProducts?.products
  )

  const activeSubscription = useSelector(
    (state: AppState) => state?.subscription.activeSubscription
  )

  const paymentMethods = useSelector(
    (state: AppState) => state?.billingInfo.paymentMethods?.payment_methods
  )

  let currentSubscription: any = null

  const phoneDetails = {
    phoneNumber: "",
    phoneExtension: "",
  }

  let billingInfo = {
    address1: "",
    address2: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
  }

  let creditCardInfo = {
    brand: "",
    last_4: "",
    exp_month: "",
    exp_year: "",
  }

  let creditCardInfoAvailable = false

  if (paymentMethods && paymentMethods.length) {
    const { brand, last_4, exp_month, exp_year, address } = paymentMethods[0]
    creditCardInfo = { brand, last_4, exp_month, exp_year }
    creditCardInfoAvailable = true

    if (address && Object.keys(address).length > 0) {
      const { address_1, address_2, city, state, postal_code, country } =
        address

      billingInfo = {
        address1: address_1,
        address2: address_2 ? address_2 : "",
        city,
        state,
        postalCode: postal_code,
        country,
      }
    }
  }

  // Update subscription state when api is successful and updates store
  if (activeSubscription && activeSubscription.length > 0) {
    activeSubscription.forEach((subscription: any) => {
      if (subscription.name.indexOf("ARE 5.0") >= 0) {
        currentSubscription = subscription
      }
    })
  }

  const checkLoggedInState = async () => {
    try {
      const productIds = [id!]

      const resp = await dispatch(getProducts({ productIds }))

      if (!resp.payload.products.length) {
        window.location.href = `${process.env.REACT_APP_HUBSPOT_REDIRECT_URL}`
      }

      const product = resp.payload.products[0]

      window.dataLayer.push({
        event: "addToCart",
        price: `Product price: ${(product?.amount / 100).toFixed(2)}`,
        ecommerce: {
          items: [
            {
              item_name: `${product?.name}`,
              item_id: `${product?.id}`,
              price: (product?.amount / 100).toFixed(2),
              item_category: ``,
              item_category2: ``,
              quantity: "1",
            },
          ],
        },
      })

      return resp.payload.products
    } catch (e) {
      return
    }
  }

  const checkRedirect = () => {
    const directToPaymentInfo = getCookie("directToPaymentInfo")
    if (directToPaymentInfo && directToPaymentInfo !== "0") {
      setPage(1)
    }
    setCookie("directToPaymentInfo", "0")
    removeCookie("directToPaymentInfo")
  }

  const getSubscriptionInfo = async (_products: Product[], paymentAddress?: Address) => {
    try {
      const { payload, type } = await dispatch(getActiveSubscriptions({ auth }))

      if (type === getActiveSubscriptions.rejected.type) {
        throw new Error("Error getting member subscription")
      } else {
        const subscription: Subscription = payload.subscriptions.filter(
          (row: Subscription) => row.name.includes("ARE 5.0")
        )[0]
        if (subscription) {
          await checkProration(subscription, _products, paymentAddress)
        }
      }
    } catch (error) {
      console.error(error)
      setOpen(true)
    }
  }

  const getPaymentInfo = async () => {
    const { payload, type } = await dispatch(getPaymentMethods({ auth }))
    
    let paymentAddress
    if (payload.payment_methods && payload.payment_methods) {
      const tempAddress = payload.payment_methods[0].address
      paymentAddress = {
        address1: tempAddress.address_1,
        address2: tempAddress.address_2 ? tempAddress.address_2 : "",
        city: tempAddress.city,
        state: tempAddress.state,
        postal_code: tempAddress.postal_code,
        country: tempAddress.country,
      }
    }

    // Prevent infinte spinner for non-primary B2B admin accounts
    if (type === getPaymentMethods.fulfilled.type)
      return paymentAddress
    else
      return
  }

  const checkProration = async (
    subscription: Subscription,
    _products: Product[],
    paymentAddress?: Address
  ) => {
    if (
      _products &&
      _products.length > 0 &&
      subscription &&
      subscription.name &&
      _products[0]?.name.indexOf(subscription?.name) === -1
    ) {
      // get proration information
      const items: any = []

      _products.map((product) =>
        items.push({
          id: product.id,
          price: (product.amount / 100).toFixed(2),
          qty: "1",
          name: product?.name,
          target_subscription_id: subscription.id,
        })
      )

      await dispatch(
        getProration({
          auth,
          items,
          coupon: couponCode,
          address: paymentAddress,
        })
      )
    }
  }

  const loadPage = useCallback(async () => {
    if (!isPageLoaded) {
      setCouponCode("")
    }
    const _products = await checkLoggedInState()
    if (auth.isAuthenticated) {
      checkRedirect()
      const paymentAddress = await getPaymentInfo()
      await getSubscriptionInfo(_products, paymentAddress)
    }

    await dispatch(validateOrgDiscount({ priceId: id ?? "", orgId: orgId() }))

    setPageLoadingComplete(true)
    setIsPageLoaded(true)
    // eslint-disable-next-line
  }, [auth.isAuthenticated])

  useEffect(() => {
    loadPage()
  }, [loadPage])

  const renderPageContent = () => {
    switch (page) {
      case 1:
        return (
          <PaymentInfo
            setPage={setPage}
            currentSubscription={currentSubscription}
            creditCardInfoAvailable={creditCardInfoAvailable}
            creditCardInfo={creditCardInfo}
            billingInfo={billingInfo}
            phoneDetails={phoneDetails}
            getPaymentInfo={getPaymentInfo}
            pageLoadingComplete={pageLoadingComplete}
            couponCode={couponCode}
            setCouponCode={setCouponCode}
          />
        )
      case 2:
        return (
          <SecureCheckout
            isAREProduct={true}
            setPage={setPage}
            couponCode={couponCode}
            setCouponCode={setCouponCode}
          />
        )
      default:
        return <CartContainer />
    }
  }

  return (
    <MainContentContainer>
      <ErrorModal open={open} setOpen={setOpen} endpoint={"subscription"} />
      {renderPageContent()}
      <SideContainer>
        <OrderSummary
          products={products}
          couponCode={couponCode}
          setCouponCode={setCouponCode}
          page={page}
          setPage={setPage}
        />
      </SideContainer>
      <ValidateNomaEmailDialog
        open={isNomaDialogOpen}
        setOpen={setIsNomaDialogOpen}
      />
    </MainContentContainer>
  )
}

export default View
